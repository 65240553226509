var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"custom-content-container pt-0",attrs:{"cols":"12","md":"9","sm":"9"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-card',{staticClass:"custom-grey-border remove-border-radius h-100",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"headline grey lighten-4"},[_c('span',{staticClass:"font-weight-700 custom-headline color-custom-blue"},[_vm._v("Contact Information")])]),_c('v-card-text',{staticClass:"pa-6 font-size-16"},[(_vm.updateEngineer && _vm.pageLoading)?[_c('v-skeleton-loader',{staticClass:"custom-skeleton my-2 custom-text-height-40px",attrs:{"type":"text"}})]:[_c('label',{staticClass:"font-weight-700 font-size-16",attrs:{"for":"full_name"}},[_vm._v("Full name")]),_c('v-text-field',{attrs:{"id":"full_name","dense":"","filled":"","placeholder":"Full name","solo":"","flat":""},model:{value:(_vm.emergencyContact.name),callback:function ($$v) {_vm.$set(_vm.emergencyContact, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"emergencyContact.name"}})],(_vm.updateEngineer && _vm.pageLoading)?[_c('v-skeleton-loader',{staticClass:"custom-skeleton my-2 custom-text-height-40px",attrs:{"type":"text"}})]:[_c('label',{staticClass:"font-weight-700 font-size-16",attrs:{"for":"mobile_number"}},[_vm._v("Phone number")]),_c('PhoneTextField',{attrs:{"id":"mobile_number","value":_vm.emergencyContact.mobile},model:{value:(_vm.emergencyContact.mobile),callback:function ($$v) {_vm.$set(_vm.emergencyContact, "mobile", $$v)},expression:"emergencyContact.mobile"}})],(_vm.updateEngineer && _vm.pageLoading)?[_c('v-skeleton-loader',{staticClass:"custom-skeleton my-2 custom-text-height-40px",attrs:{"type":"text"}})]:[_c('label',{staticClass:"font-weight-700 font-size-16"},[_vm._v("Email")]),_c('v-text-field',{attrs:{"rules":[
                    _vm.validateRules.validEmail(_vm.emergencyContact.email, 'Email'),
                    _vm.validateRules.minLength(
                      _vm.emergencyContact.email,
                      'Email',
                      1
                    ),
                    _vm.validateRules.maxLength(
                      _vm.emergencyContact.email,
                      'Email',
                      255
                    ),
                  ],"dense":"","filled":"","placeholder":"Email","solo":"","flat":""},model:{value:(_vm.emergencyContact.email),callback:function ($$v) {_vm.$set(_vm.emergencyContact, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"emergencyContact.email"}})],(_vm.updateEngineer && _vm.pageLoading)?[_c('v-skeleton-loader',{staticClass:"custom-skeleton my-2 custom-text-height-40px",attrs:{"type":"text"}})]:[_c('label',{staticClass:"font-weight-700 font-size-16"},[_vm._v("Relation")]),_c('v-text-field',{attrs:{"rules":[
                    _vm.validateRules.minLength(
                      _vm.emergencyContact.relation,
                      'Relation',
                      1
                    ),
                    _vm.validateRules.maxLength(
                      _vm.emergencyContact.relation,
                      'Relation',
                      255
                    ),
                  ],"dense":"","filled":"","placeholder":"Relation","solo":"","flat":""},model:{value:(_vm.emergencyContact.relation),callback:function ($$v) {_vm.$set(_vm.emergencyContact, "relation", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"emergencyContact.relation"}})]],2)],1)],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-card',{staticClass:"custom-grey-border remove-border-radius h-100",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"headline grey lighten-4"},[_c('span',{staticClass:"font-weight-700 custom-headline color-custom-blue"},[_vm._v("Address Information")])]),_c('v-card-text',{staticClass:"pa-6 font-size-16"},[_c('v-row',[_c('v-col',{attrs:{"md":"6","lg":"6"}},[_c('label',{staticClass:"font-size-16 font-weight-500 required"},[_vm._v("Country ")]),_c('v-autocomplete',{staticClass:"pt-0 mt-0",attrs:{"items":_vm.countryList,"hide-details":"","flat":"","rules":[
                      _vm.validateRules.required(
                        _vm.emergencyContact.emergency_country_name,
                        'Country'
                      ),
                    ],"solo":"","dense":"","item-value":"value","item-text":"text","placeholder":"Country","color":"cyan","item-color":"cyan"},on:{"change":function($event){return _vm.countryCheck($event)}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-capitalize font-weight-500 font-size-16"},[_vm._v(_vm._s(item.text))])],1)]}}]),model:{value:(_vm.emergencyContact.emergency_country_name),callback:function ($$v) {_vm.$set(_vm.emergencyContact, "emergency_country_name", $$v)},expression:"emergencyContact.emergency_country_name"}})],1),_c('v-col',{attrs:{"md":"6","lg":"6"}},[_c('label',{staticClass:"font-size-16 font-weight-500"},[_vm._v("Search Address ")]),_c('SearchAddress',{attrs:{"variant":"outlined","density":"compact","hide-details":"","country":_vm.emergencyContact.emergency_country_name,"country-code":_vm.country_code,"placeholder":'Search Address'},on:{"update:address":(data) => _vm.setAddress(data)}})],1)],1),(_vm.emergencyContact.emergency_country_name == 'singapore')?[(_vm.updateEngineer && _vm.pageLoading)?[_c('v-skeleton-loader',{staticClass:"custom-skeleton my-2 custom-text-height-40px",attrs:{"type":"text"}})]:[_c('label',{staticClass:"font-weight-700 font-size-16"},[_vm._v("Unit no.")]),_c('v-text-field',{attrs:{"rules":[
                      _vm.validateRules.minLength(
                        _vm.emergencyContact.unit_no,
                        'Unit no.',
                        1
                      ),
                      _vm.validateRules.maxLength(
                        _vm.emergencyContact.unit_no,
                        'Unit no.',
                        255
                      ),
                    ],"dense":"","filled":"","placeholder":"Unit no.","solo":"","flat":""},model:{value:(_vm.emergencyContact.unit_no),callback:function ($$v) {_vm.$set(_vm.emergencyContact, "unit_no", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"emergencyContact.unit_no"}})],(_vm.updateEngineer && _vm.pageLoading)?[_c('v-skeleton-loader',{staticClass:"custom-skeleton my-2 custom-text-height-40px",attrs:{"type":"text"}})]:[_c('label',{staticClass:"font-weight-700 font-size-16"},[_vm._v("Address Line 1")]),_c('v-text-field',{attrs:{"rules":[
                      _vm.validateRules.minLength(
                        _vm.emergencyContact.street_1,
                        'Address Line 1',
                        1
                      ),
                      _vm.validateRules.maxLength(
                        _vm.emergencyContact.street_1,
                        'Address Line 1',
                        255
                      ),
                    ],"dense":"","filled":"","placeholder":"Address Line 1","solo":"","flat":""},model:{value:(_vm.emergencyContact.street_1),callback:function ($$v) {_vm.$set(_vm.emergencyContact, "street_1", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"emergencyContact.street_1"}})],(_vm.updateEngineer && _vm.pageLoading)?[_c('v-skeleton-loader',{staticClass:"custom-skeleton my-2 custom-text-height-40px",attrs:{"type":"text"}})]:[_c('label',{staticClass:"font-weight-700 font-size-16"},[_vm._v("Address Line 2")]),_c('v-text-field',{attrs:{"rules":[
                      _vm.validateRules.minLength(
                        _vm.emergencyContact.street_2,
                        'Address Line 2',
                        1
                      ),
                      _vm.validateRules.maxLength(
                        _vm.emergencyContact.street_2,
                        'Address Line 2',
                        255
                      ),
                    ],"dense":"","filled":"","placeholder":"Address Line 2","solo":"","flat":""},model:{value:(_vm.emergencyContact.street_2),callback:function ($$v) {_vm.$set(_vm.emergencyContact, "street_2", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"emergencyContact.street_2"}})],(_vm.updateEngineer && _vm.pageLoading)?[_c('v-skeleton-loader',{staticClass:"custom-skeleton my-2 custom-text-height-40px",attrs:{"type":"text"}})]:[_c('label',{staticClass:"font-weight-700 font-size-16"},[_vm._v("Postal Code")]),_c('v-text-field',{attrs:{"rules":[
                      _vm.validateRules.minLength(
                        _vm.emergencyContact.postal_code,
                        'Postal Code',
                        1
                      ),
                      _vm.validateRules.maxLength(
                        _vm.emergencyContact.postal_code,
                        'Postal Code',
                        255
                      ),
                    ],"dense":"","filled":"","placeholder":"Postal Code","solo":"","flat":""},on:{"keypress":function($event){return _vm.restrictNonNumericInput($event)}},model:{value:(_vm.emergencyContact.postal_code),callback:function ($$v) {_vm.$set(_vm.emergencyContact, "postal_code", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"emergencyContact.postal_code"}})]]:[_c('label',{staticClass:"font-size-16 font-weight-500 required"},[_vm._v("Full Address")]),_c('v-textarea',{attrs:{"rules":[
                    _vm.validateRules.required(
                      _vm.emergencyContact.street_1,
                      'Full Address'
                    ),
                    _vm.validateRules.maxLength(
                      _vm.emergencyContact.street_1,
                      'Full Address',
                      255
                    ),
                  ],"auto-grow":"","dense":"","filled":"","placeholder":"Full Address","solo":"","flat":"","row-height":"15","color":"cyan"},model:{value:(_vm.emergencyContact.street_1),callback:function ($$v) {_vm.$set(_vm.emergencyContact, "street_1", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"emergencyContact.street_1"}})]],2)],1)],1)],1)],1),_c('v-col',{staticClass:"custom-sidebar-container white lighten-3",attrs:{"cols":"12","md":"3","sm":"3"}},[_vm._l((_vm.fieldDescriptions),function(fieldInfo,indx){return [(fieldInfo.field == _vm.currentActiveField)?_c('div',{key:indx,staticClass:"help-sidebar"},[_c('div',{staticClass:"help-sidebar-header"},[(fieldInfo.title)?_c('h3',{staticClass:"section-header-title"},[_vm._v(" "+_vm._s(fieldInfo.title)+" ")]):_vm._e()]),(fieldInfo.description)?_c('p',{staticClass:"help-sidebar-description"},[_vm._v(" "+_vm._s(fieldInfo.description)+" ")]):_vm._e(),(fieldInfo.footer)?_c('h5',{staticClass:"help-sidebar-footer-title"},[_vm._v(" "+_vm._s(fieldInfo.footer)+" ")]):_vm._e(),(fieldInfo.footer_content)?_c('p',{staticClass:"help-sidebar-footer-text"},[_vm._v(" "+_vm._s(fieldInfo.footer_content)+" ")]):_vm._e()]):_vm._e()]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }